<template>
  <div class="d-spinner"></div>
</template>

<style scoped lang="scss">
.d-spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
}

.d-spinner::after {
  content: " ";
  display: block;
  width: 10px;
  height: 10px;
  margin: 1px;
  border-radius: 50%;
  border: 2px solid currentColor;
  border-color: #005aab #005aab #005aab transparent;
  animation: d-spinner 1.2s linear infinite;
}

@keyframes d-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
